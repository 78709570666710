// ./bin/webpack

import datepickerFactory from 'jquery-datepicker';

global.$ = require('jquery')
require('jquery-ui')
global.moment = require('moment')

datepickerFactory($);

$(function () {
  $( '.datepicker' ).datepicker({
    changeMonth: true,
    changeYear: true,
    constrainInput: false,
    showOn: 'button',
    buttonImageOnly: true,
    buttonImage: '/assets/icon_cal.png',
    dateFormat: 'MM d, yy',
    yearRange: '1900:' + moment().format( 'YYYY' )
  });

  $( 'input#today' ).val( moment().format( 'L' ) );

  // TODO: This isn't working
  $( 'input[type="text"]:first' ).focus();
});
